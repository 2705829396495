.vendor-tile {
	display: flex;
	flex-shrink: 0;
	flex-direction: column;
	width: 90%;
	padding: 5px 5px 5px 5px;
	background-color: #FFFFFF;
	margin-top: 10px;
	margin-bottom: 10px;
	border-radius: 10px;
	box-shadow: 3px 4px 11px 2px rgba(0, 0, 0, 0.2);
	z-index: 2;
	/*height: 200px;*/
}

.vendor-popup {
	display: flex;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.vendor-tile.highlighted {
	box-shadow: 0 0 11px 0 rgba(69, 131, 177, 0.4);
	animation-name: activeBounce;
	animation-duration: 1s;
	animation-iteration-count: infinite;
}

.vendor-tile.pre-render {
	opacity: 0;
	transform: translateY(15px);
}

.vendor-tile.post-render {
	opacity: 1;
	transition: opacity .2s ease, transform .2s ease;
}

.vendor-name {
	text-align: center;
	color: #4583b1;
	font-size: 1.4em;
	font-weight: bold;
}

.order-details .address-lines .vendor-name {
	text-align: left;
}

.vendor-subtitle {
	font-style: italic;
}

.vendor-section-title {
	font-weight: bold;
}

.address-section {
	display: flex;
	align-items: center;
}

.address-section .pointer-icon {
	color: #4583b1;
	margin-left: 10px;
	margin-right: 10px;
}

.platinum-star {
	color: #40d3ba;
}

.preferred-star {
	color: #FFD700;
}

.paren-star {
	white-space: nowrap;
	font-style: normal !important;
}

.vendor-popup .address-section {
	max-width: 250px;
}

.vendor-popup .address-section .pointer-icon {
	margin-left: 0;
}

.vendor-popup .address-section span {
	font-size: 16px;
}

.vendor-popup span.vendor-address-2,
.vendor-popup span.vendor-city-state-zip {
	font-weight: normal;
}

.vendor-popup .register-button,
.address-section .register-button {
	position: relative;
	text-align: center;
	overflow: hidden;
	padding: 10px 10px 10px 10px;
	margin-left: auto;
	margin-right: 20px;
	white-space: nowrap;
	border: 2px solid #4583b1;
	border-radius: 4px;
	z-index: 0;
	cursor: pointer;
	color: #fff; /*4583b1*/
	font-weight: bold;
	min-width: 124px;
	background-color: #4583b1;
}

.vendor-popup .register-button {
	min-width: 200px;
	font-size: 16px;
}

.vendor-popup.order-details .register-button {
	margin-right: 0;
}

.vendor-popup .register-button.inactive {
	color: darkgray;
	border: 2px solid darkgray;
}

.vendor-popup .address-section .register-button {
	margin-left: 10px;
	margin-right: auto;
}

.vendor-popup .register-button:hover,
.address-section .register-button:hover {
	color: #4583b1;
}

.vendor-popup .register-button:after,
.address-section .register-button:after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	transition: transform .2s ease;
	transform: translateX(101%);
	z-index: -1;
}

.vendor-popup .register-button:hover:after,
.address-section .register-button:hover:after {
	transition: transform .2s ease;
	transform: translateX(0);
}

.address-lines {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.vendor-change {
	color: #4583b1;
	cursor: pointer;
	text-decoration: underline;
}

.vendor-address-1,
.vendor-address-2,
.vendor-city-state-zip {
	/*
	font-weight: bold;
	*/
	font-size: 1em;
}

.vendor-tile hr,
.vendor-popup hr{
	width: 80%;
	height: 1px;
	margin: 10px auto 10px;
	color: #e0e0e0;
}

.vendor-popup hr {
	margin: 5px auto 5px;
}

.vendor-popup .vendor-hours-box {
	padding-bottom: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.vendor-hours-box {
	display: flex;
	flex-direction: column;
	align-self: center;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
	background-color: rgb(228, 238, 243);
	padding: 0 10px 10px 10px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

#vendor-showmore .vendor-hours-box {
	padding-top: 10px;
	padding-bottom: 10px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

#vendor-showmore span {
	font-style: italic;
}

.vendor-showrest {
	color: #4583b1;
	text-decoration: underline;
	cursor: pointer;
}

.popup-tip-anchor {
	height: 0;
	position: absolute;
	width: 300px;
}

.popup-bubble-anchor {
	position: absolute;
	width: 100%;
	bottom: 57px;
	left: 0;
}

.popup-bubble-anchor.below {
	bottom: -150px;
}

.popup-bubble-anchor.below .popup-bubble-content {
	top: 20px;
}

.popup-bubble-anchor::after {
	content: "";
	position: absolute;
	left: 0;
	/* Center the tip horizontally. */
	transform: translate(-50%, 0);
	/* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
	width: 0;
	height: 0;
	/* The tip is 8px high, and 12px wide. */
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
}

.popup-bubble-anchor.above::after {
	top: 0;
	border-top: /* TIP_HEIGHT= */ 8px solid white;
}
.popup-bubble-anchor.below::after {
	top: -148px;
	border-bottom: /* TIP_HEIGHT= */ 8px solid white;
}

.popup-bubble-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 250px;
	height: 160px;
	transform: translate(-50%, -100%);
	/* Style the info window. */
	background-color: white;
	padding: 5px;
	border-radius: 5px;
	font-family: sans-serif;
	overflow-y: auto;
	box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.5);
}

.vendor-detail-cta {
	width: 100%;
	display: flex;
	justify-content: center;
}

.vendor-detail-cta .register-button {
	margin: 0;
}

.view-on-map {
	text-decoration: underline;
	color: #4583b1;
	cursor: pointer;
	font-size: .8em;
	margin-top: 5px;
}

@media screen and (max-width: 550px) {
	.vendor-name {
		font-size: 1em;
	}

	.vendor-address-1,
	.vendor-address-2,
	.vendor-city-state-zip {
		/*
		font-weight: bold;
		*/
		font-size: .8em;
		line-height: 1em;
	}
	.vendor-popup .register-button,
	.address-section .register-button {
		background-color: #4583b1;
		color: #efefef;
	}
}

@media screen and (max-width: 380px) {
	.address-section .register-button {
		font-size: .8em;
	}
}

@keyframes activeBounce {
	0% {
		box-shadow: 0 0 11px 0 rgba(69, 131, 177, 0.4);
	}
	40% {
		box-shadow: 0 0 11px 10px rgba(69, 131, 177, 0.4);
	}
}
