.slide-out-header {
	position: absolute;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
	font-size: 1.5em;
	line-height: 1em;
	width: 100%;
	text-align: center;
	color: #FFFFFF;
	background-color: #1c4856;
	padding-top: 10px;
	padding-bottom: 10px;
	max-height: 50px;
	z-index: 99;
}

.split-header {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.split-header .subtext {
	font-size: .7em;
}

.slide-out-header.jared {
	flex-direction: column;
	background-color: transparent;
	justify-content: space-between !important;
	padding-top: 0;
	padding-bottom: 0;
	max-height: none;
	height: 70px;
}

.jared .tab-container {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	font-size: .8em;
	/*
	background-color: transparent;
	*/
}

.jared .slide-out-greeting {
	width: 100%;
	background-color: rgba(59, 132, 177, .9);
	display: flex;
	flex-grow:1;
	justify-content: center;
	align-items: center;
	height: 34px;
	border-bottom: 1px solid white;
}

.jared .map-view,
.jared .list-view {
	display:flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	background-color: rgba(255,255,255,1);
	width: 100%;
	padding: 3px 10px 3px 10px;
	color: #4583b1;
	border-bottom: 1px solid #4583b1;
	height: 100%;
}

.jared .map-view.map-mode,
.jared .list-view.list-mode {
	color: white;
	background-color: #4583b1;
	background-color: rgba(59, 132, 177, 1);
}

.menu-header{
	color: white !important;
}

/*.main-nav{
	transition: none !important;
}*/

@media screen and (max-width: 1050px) {
	.slide-out-header {
		top: 60px;
	}

	/*.main-nav{
		transition: all 0.1s !important;
	}*/
}

@media screen and (max-width: 750px) {
	.slide-out-header {
		top: 108px;
	}

	/* MOBILE HAMBURGER MENU ALTERED STYLES */

	/*.main-nav{
		top: 48px;
	}
	
	.main-nav.menu-on{
		width: 70%;
		max-height: none;
		top: 48px;
		background-color: white !important;
	}

	.main-nav.menu-on ul{
		background-color: white !important;
	}

	.material-design-hamburger{
		color: white !important;
	}

	.main-nav.menu-on li a{
		color: #3fa1c2;
		font-weight: 500;
	}

	.main-nav.menu-on .menu-header{
		color: #3fa1c2 !important;
		border-bottom: 1px solid #3fa1c2;
		font-size: 24px;
		padding-bottom: 5px;
		font-weight: 600;
	}
	
	.material-design-hamburger__layer{
		background: white;
	}

	header{
		box-shadow: none;
	}*/
}

/*
.main-nav{
	background: #3fa1c2 !important;
	width: 130px;
	top: 6px;
}

.material-design-hamburger{
	zoom: 75%;
	top: 13px;
}*/
