.vendor-list {
	display: flex;
	width: 100%;
	flex-shrink: 0;
	flex-direction: column;
	align-items: center;
	/*height: 505px;*/
	overflow-y: auto;
}

.vendor-list.noscroll {
	overflow-y: hidden;
}


